'use client';

import { noop } from 'lodash';
import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  RefObject,
  useCallback,
} from 'react';

import { EVENTS } from '~/analytics/config';
import getBaseTrackingData from '~/analytics/getBaseTrackingData';
import useTrackEventCallback from '~/analytics/hooks/useTrackEventCallback';
import Link from '~/components/atoms/Link/Link';
import { getNodeText } from '~/utils';

import { BaseButtonProps, ForwardedButtonBaseRef } from './ButtonBase.types';

/**
 * Base wrapper for the functionality of interactive components suck as buttons or links, unstyled.
 * @param to The cta's link, determines whether component will render as <a> or <button>, set in the CMS
 * @param disabled If the cta is disabled
 * @param onClick Callback function for when the button is clicked
 * @param customTrackingEvent Custom event for tracking, set in the CMS. If an array, the component will loop through the array to send every event
 * @param asNonInteractive Renders a <span> instead of a button or anchor tag. Used in cases where the interactive element is the parent of the cta
 * @param type The HTML attribute for <button> element. Only accepts "submit"
 * @param className
 * @example <ButtonBase> {children} </ButtonBase>
 */
const ButtonBase = (
  props: BaseButtonProps,
  ref: ForwardedRef<ForwardedButtonBaseRef>,
) => {
  const {
    to,
    disabled,
    onClick,
    onMouseOver = noop,
    onMouseOut = noop,
    onFocus = noop,
    onBlur = noop,
    customTrackingEvent,
    asNonInteractive,
    className,
    children,
    type,
  } = props;
  const childText = getNodeText(children);
  const trackButtonClick = useTrackEventCallback(EVENTS.buttonClick);
  const trackCustomEvent = useTrackEventCallback(customTrackingEvent);
  const onClickRenderTag = useCallback(
    (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      const props = { title: String(childText), ...getBaseTrackingData() };

      trackButtonClick(props);
      trackCustomEvent(props);

      if (onClick && e) {
        onClick(e);
      }
    },
    [onClick, childText, trackButtonClick, trackCustomEvent],
  );

  if (asNonInteractive) {
    // If a button is non interactive, we simply display it as a div
    // Note that no event listener is therefore attached to it
    return (
      // We hard cast here in order to bypass the rigidity of the built-in classes
      <div ref={ref as RefObject<HTMLDivElement>} className={className}>
        {props.children}
      </div>
    );
  } else if (typeof to !== 'undefined' && to.type && (to.hash || to.url)) {
    // If the link has a to property, it means it is a Link, so we display it as such
    return (
      <Link ref={ref} onClick={onClickRenderTag} to={to} className={className}>
        {props.children}
      </Link>
    );
  } else {
    // Else it means our element is simply interactive, and should just be a button
    return (
      <button
        onClick={onClickRenderTag}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onFocus={onFocus}
        onBlur={onBlur}
        {...(type ? { type } : {})}
        {...(disabled ? { disabled } : {})}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        className={className}
        // Add the data attribute for Adobe Analytics
        daa-ll={to?.label || childText}
      >
        {props.children}
      </button>
    );
  }
};

export default forwardRef<ForwardedButtonBaseRef, BaseButtonProps>(ButtonBase);
