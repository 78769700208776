'use client';
import { ForwardedRef, forwardRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import { cn } from '~/utils';

import styles from './Shadow.module.css';
import shadowStore from './Shadow.store';
import { ShadowProps } from './Shadow.types';

const Shadow = (
  { children, className, style, shadowClassName }: ShadowProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const shadows = shadowStore.getState().shadows;
  if (shadows) {
    return (
      <div
        className={cn(
          className,
          styles.shadowWrapper,
          typeof children === 'undefined' && styles.standalone,
        )}
        style={style}
        ref={ref}
      >
        <div className={cn(styles.shadow, shadowClassName)}>
          <div className={styles.bottom}>
            <div className={styles.bottomLeft}>
              <Image
                source={shadows.bottomLeft}
                className={styles.shadowImage}
                quality={80}
                preload={true}
                columns={{
                  md: 8,
                  sm: 4,
                }}
              />
            </div>
            <div className={styles.bottomMiddle}>
              <Image
                source={shadows.bottomMiddle}
                className={styles.shadowImage}
                quality={80}
                preload={true}
                columns={{
                  md: 8,
                  sm: 4,
                }}
              />
            </div>
            <div className={styles.bottomRight}>
              <Image
                source={shadows.bottomRight}
                className={styles.shadowImage}
                quality={80}
                preload={true}
                columns={{
                  md: 8,
                  sm: 4,
                }}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.topRight}>
              <Image
                source={shadows.topRight}
                className={styles.shadowImage}
                quality={80}
                preload={true}
                columns={{
                  md: 8,
                  sm: 4,
                }}
              />
            </div>
            <div className={styles.middleRight}>
              <Image
                source={shadows.middleRight}
                className={styles.middleRightImg}
                quality={80}
                preload={true}
                columns={{
                  md: 8,
                  sm: 4,
                }}
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  }

  return (
    <div
      className={cn(
        className,
        styles.shadowWrapper,
        typeof children === 'undefined' && styles.standalone,
      )}
      ref={ref}
    >
      <div />
      {children}
    </div>
  );
};

export default forwardRef<HTMLDivElement, ShadowProps>(Shadow);
