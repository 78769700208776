import { useCallback } from 'react';

import trackEventAdobeAnalytics from '~/analytics/trackEventAdobeAnalytics';
import trackEventSegment from '~/analytics/trackEventSegment';

/**
 * Creates and returns a callback function that is bound to the event name. It expects
 * a properties object when invoked. Since hooks cannot be conditionally invoked, we need
 * to move the condition into the callback instead where we check for a valid eventName
 *
 * @param eventName An event name to track, or undefined for noop.
 * @returns Callback function
 */
const useTrackEventCallback = (
  eventName?: string | Array<string | undefined>,
) => {
  return useCallback(
    (properties: Record<string, string>, callback?: () => void) => {
      if (typeof eventName === 'string') {
        trackEventAdobeAnalytics(eventName);
        trackEventSegment(eventName, properties, callback);
      } else if (Array.isArray(eventName)) {
        for (let i = 0; i < eventName.length; i++) {
          const event = eventName[i];
          if (typeof event === 'string') {
            trackEventAdobeAnalytics(event);
            if (i == eventName.length - 1) {
              trackEventSegment(event, properties, callback);
            } else {
              trackEventSegment(event, properties);
            }
          }
        }
      }
    },
    [eventName],
  );
};

export default useTrackEventCallback;
