import { CMSImage, ImageBuilderOptions } from '../Image.types';
import urlFor from './urlFor';

const getImageUrl = (
  image: CMSImage,
  { width, height, quality = 80, blur, isCover }: ImageBuilderOptions,
) => {
  let srcObject = urlFor(image)
    .width(width)
    .height(height)
    .quality(quality)
    .auto('format');

  if (isCover && image.crop) {
    srcObject = srcObject
      .fit('crop')
      .crop('focalpoint')
      .focalPoint(image.hotspot?.x || 0.5, image.hotspot?.y || 0.5);
  }

  if (blur) {
    srcObject = srcObject.blur(blur);
  }

  if (image.crop) {
    const cropWidthPercent = 1 - image.crop.left - image.crop.right;
    const croppedWidth = Math.round(image.asset.width * cropWidthPercent);
    const cropHeightPercent = 1 - image.crop.top - image.crop.bottom;
    const croppedHeight = Math.round(image.asset.height * cropHeightPercent);

    srcObject = srcObject.rect(
      Math.round(image.crop?.left * image.asset.width),
      Math.round(image.crop?.top * image.asset.height),
      croppedWidth,
      croppedHeight,
    );
  }

  const url = srcObject.url();

  return url;
};

export default getImageUrl;
