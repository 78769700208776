'use client';

import { breakpoints } from '@frameio-bs/tokens';
import gsap from 'gsap';
import NextImage from 'next/image';
import React, { CSSProperties, ForwardedRef, forwardRef, useMemo } from 'react';

import { cn } from '~/utils';

import styles from './Image.module.css';
import { ImageBuilderOptions, ImageProps } from './Image.types';
import { isGoogleBot } from './utils';
import getImageUrl from './utils/getImageUrl';

/**
 * Image component for assets coming from Sanity. It will instanciate an `<img>` element with all the
 * necessary attributes (like `alt`, `srcSet`, `sizes` and optionally `source` elements with relevant
 * breakpoints)
 * @param source CMSImage
 * @param {boolean} [preload] If the image should be preloaded instead of lazy-loaded
 * @param {number} [quality] The quality of the image – 0-100
 * @param {number} [dpr] Override the DPR value of the image
 * @param {number} [blur] Blurs the image on the Image side
 * @param {Function} [onReady] Callback firing on image load - Receives the img element as a parameter
 * @param {string} [className] The className added to the `<figure>` element
 * @param {RefObject} [ref] The ref pointing to the `<img>` element
 * @param {boolean} [animated] specifies if the image should fade in automatically once loaded
 * @param {number} [width] The desired width of the image
 * @param {number} [height] The desired height of the image
 * @param columns (single number or by breakpoint in object) specifies how many columns the image should take up
 * @param aspectRatio (single number or by breakpoint in object) specifies the aspect ratio of the image
 * @returns A figure element containing an image
 * @example
 * <Image source={mySource} className={myStyle} />
 * <Image source={mySource} className={myStyle} preload={true} />
 */

const BREAKPOINTS: {
  [key: string]: number;
} = {};

breakpoints.forEach((breakpoint) => {
  BREAKPOINTS[breakpoint.name] = breakpoint.min === 0 ? 1 : breakpoint.min;
});

const IMAGE_SRC_SET_WIDTHS = [256, 750, 1200, 1920, 2400];

const columnToVw = (column: number | string = 12) => {
  if (typeof column === 'string') return column;
  const isFullWidth = column === 12;
  // The max grid width is 1440px, we want to ensure that the image never exceeds this width
  // This not being used on full width images
  const maxWidth = 1440 * (column / 12);
  if (isFullWidth) {
    return `${Math.ceil((column / 12) * 100)}vw`;
  } else {
    return `min(${Math.ceil((column / 12) * 100)}vw, ${maxWidth}px)`;
  }
};

// This is used specifically to remove native functionality of
// generating image srcSet within _next/ directory which is
// then served from Vercel, and to instead request all images
// from the Sanity CDN directly
const imageLoader = ({ src, width }: { src: string; width: number }) => {
  const url = new URL(src);
  const originalHeight = parseInt(url.searchParams.get('h') || '0');
  const originalWidth = parseInt(url.searchParams.get('w') || '0');
  url.searchParams.set('auto', 'format');
  url.searchParams.set('fit', 'max');
  url.searchParams.set('w', width.toString());

  // Googlebots are agressively crawling images and downloading them
  // so we need to ensure they are served as webp otherwise they may get served uncompressed images
  if (isGoogleBot()) {
    url.searchParams.set('fm', 'webp');
  }

  if (originalHeight && originalWidth) {
    url.searchParams.set(
      'h',
      `${Math.round((width * originalHeight) / originalWidth)}`,
    );
  }

  return url.href;
};

const Image = (props: ImageProps, ref: ForwardedRef<HTMLImageElement>) => {
  const {
    className,
    source,
    columns = 12,
    onReady,
    dpr,
    blur,
    preload,
    animated = true,
    quality = 80,
    width,
    height,
    aspectRatio,
    isCover = false,
  } = props;

  const sourceProps = useMemo(() => {
    const props: ImageBuilderOptions = {
      width: width || source?.asset?.width,
      height: height || source?.asset?.height,
      dpr,
      blur,
      quality,
      isCover,
    };

    if (typeof aspectRatio === 'number') {
      props.height = Math.round(props.width / aspectRatio);
    }

    return props;
  }, [source, dpr, blur, quality, width, height, aspectRatio, isCover]);

  const sizes = useMemo(() => {
    if (typeof columns === 'number') {
      return `${columnToVw(columns)}`;
    } else if (typeof columns === 'string') {
      return columns;
    } else {
      const imageSizeArray = Object.keys(BREAKPOINTS)
        .reverse()
        .map((breakpointKey) => {
          if (columns[breakpointKey as keyof typeof columns]) {
            const value = columnToVw(
              columns[breakpointKey as keyof typeof columns],
            );
            return `(min-width: ${BREAKPOINTS[breakpointKey]}px) ${value}`;
          }
        })
        .filter((item) => item)
        .join(', ');

      return imageSizeArray;
    }
  }, [columns]);

  const croppedImageSources = useMemo(() => {
    if (typeof aspectRatio === 'number' || !aspectRatio) return null;

    const imageSourcesByBreakpoint = Object.keys(BREAKPOINTS)
      .reverse()
      .map((breakpointKey) => {
        if (aspectRatio[breakpointKey as keyof typeof aspectRatio]) {
          const value = aspectRatio[breakpointKey as keyof typeof aspectRatio];

          if (!value) return null;

          const vw = columnToVw(columns[breakpointKey as keyof typeof columns]);

          const srcSet = IMAGE_SRC_SET_WIDTHS.map((width) => {
            return `${getImageUrl(source, {
              width,
              height: Math.round(width / value),
              isCover,
            })} ${width}w`;
          }).join(', ');

          return (
            <source
              srcSet={srcSet}
              media={`(min-width: ${BREAKPOINTS[breakpointKey]}px)`}
              sizes={vw}
              key={breakpointKey}
            />
          );
        }
      })
      .filter((item) => item);

    return imageSourcesByBreakpoint;
  }, [aspectRatio, source, columns, isCover]);

  const imageElement = (
    <NextImage
      loader={imageLoader}
      onLoad={(e) => {
        const target = e.target as HTMLImageElement;
        if (onReady) onReady(target);
        if (target && animated) {
          gsap.to(target, {
            opacity: 1,
            duration: 0.4,
          });
        }
      }}
      ref={ref}
      priority={preload}
      className={cn(
        className,
        styles.image,
        animated && styles.animated,
        isCover && styles.isCover,
      )}
      alt={source?.alt || ''}
      src={getImageUrl(source, sourceProps)}
      style={
        {
          '--object-position': source?.hotspot
            ? `${source?.hotspot.x * 100}% ${source?.hotspot.y * 100}%`
            : 'center',
        } as CSSProperties
      }
      sizes={sizes}
      width={sourceProps.width || source?.asset?.width}
      height={sourceProps.height || source?.asset?.height}
    />
  );
  const fallbackImage = useMemo(() => {
    return (
      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={`${getImageUrl(source, { ...sourceProps, quality: 10 })}&fm=jpg`}
          alt={source?.alt || ''}
          className={cn(className, styles.image, isCover && styles.isCover)}
          width={sourceProps.width || source?.asset?.width}
          height={sourceProps.height || source?.asset?.height}
        />
      </noscript>
    );
  }, [source, sourceProps, className, isCover]);

  if (!source) return null;

  if (croppedImageSources && imageElement) {
    return (
      <picture>
        {croppedImageSources}
        {imageElement}
        {fallbackImage}
      </picture>
    );
  }

  return (
    <picture>
      {imageElement}
      {fallbackImage}
    </picture>
  );
};

export default forwardRef(Image);
